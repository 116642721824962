// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.

require('@rails/ujs').start();
require('@rails/activestorage').start();
require('channels');
require('bootstrap');

import { Calendar } from '@fullcalendar/core';
import dayGridPlugin from '@fullcalendar/daygrid';
import trumbowyg from 'trumbowyg'
import svgIcons from 'trumbowyg/dist/ui/icons.svg';
import Noty from 'noty';
import $ from 'jquery'

global.$ = jQuery
window.FullCalendar = Calendar;
window.dayGridPlugin = dayGridPlugin;
window.trumbowyg = trumbowyg
window.trumbowygSvgPath = svgIcons
window.Noty = Noty

require('custom/theme');

import 'stylesheets/application';
// Uncomment to copy all static images under ../images to the output folder and reference
// them with the image_pack_tag helper in views (e.g <%= image_pack_tag 'rails.png' %>)
// or the `imagePath` JavaScript helper below.
//
const images = require.context('../images', true);
const imagePath = (name) => images(name, true);
import "controllers"
