import { Controller } from "stimulus"

export default class extends Controller {
  static targets = ["reviewProject", "directApprove", "requestRevision", "directReject", "content", "reviewForm", "approveForm", "requestRevisionForm", "rejectForm", "approvalMessage", "reviewerNotes", "rejectionMessage", "revisionMessage", "submitMode","teamNotesForm"]

  connect() {
    this.showContent()
  }

  showReviewForm(event) {
    this.reviewerNotesTarget.required = true
    this.hideContent()
    this.reviewFormTarget.classList.remove("d-none")
    this.submitModeTarget.value = "review"
  }

  showApprovalForm(event) {
    this.approvalMessageTarget.required = true
    this.hideContent()
    this.approveFormTarget.classList.remove("d-none")
    this.submitModeTarget.value = "approve"
  }

  showRequestRevisionForm(event) {
    this.revisionMessageTarget.required = true
    this.hideContent()
    this.requestRevisionFormTarget.classList.remove("d-none")
    this.submitModeTarget.value = "require_revision"
  }

  showRejectionForm(event) {
    this.rejectionMessageTarget.required = true
    this.hideContent()
    this.rejectFormTarget.classList.remove("d-none")
    this.submitModeTarget.value = "reject"
  }

  hideContent(){
    this.contentTarget.classList.add("d-none")
    this.teamNotesFormTarget.classList.add("d-none")
  }

  showContent(){
    this.reviewFormTarget.classList.add("d-none")
    this.approveFormTarget.classList.add("d-none")
    this.requestRevisionFormTarget.classList.add("d-none")
    this.rejectFormTarget.classList.add("d-none")
    this.contentTarget.classList.remove("d-none")
    this.teamNotesFormTarget.classList.remove("d-none")
    this.approvalMessageTarget.required = false
    this.ideaRatingTarget.required = false
    this.teamRatingTarget.required = false
    this.hypeRatingTarget.required = false
    this.tokenomicsRatingTarget.required = false
    this.reviewerNotesTarget.required = false
    this.rejectionMessageTarget.required = false
    this.revisionMessageTarget.required = false
    this.submitModeTarget.value = ""
  }
}
