import { Controller } from "stimulus"

export default class extends Controller {
  static targets = ["totalRaise", "tokenPrice","tokenAmount"]

  connect() {
    if (this.hasTotalRaiseTarget) { this.calcTotalRaise() }
  }

  calcTotalRaise() {
    this.totalRaiseTarget.innerHTML = "$" + (this.tokenPriceTarget.value * this.tokenAmountTarget.value).toFixed(2)
  }

}